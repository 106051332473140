import React, { PureComponent } from 'react';
import './stylesheets/basicControls.css';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

let alpha = params.alpha;

class BasicControls extends PureComponent {
    state = { 

     }

    render() {
        if(alpha == "true"){
            return(         
                <div className='box' style={{backgroundColor: this.props.color}}>
                    <div className='boxHeader' onClick={this.props.collapse}>
                        BASIC CONTROLS
                    </div>
                    <div className='buttonBox'>     
                        <div className='buttonRow'>CLOCK 
                            <button attr1='clockShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='clockHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRow'>TOWER 
                            <button attr1='towerShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='towerHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRow'>RESULTS 
                            <button attr1='resultsShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='resultsPage' className="controlBUTTON" onClick={this.props.controlSend}>Page</button>
                            <button attr1='resultsHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div>RACE SELECT
                            <button attr1='getMeetings' className="controlBUTTON" onClick={this.props.controlSend}>GET</button>
                            <select defaultValue={this.props.selectedMeeting} style={{width:"210px"}} id="meeting" onChange={() => this.props.getRaceList(document.getElementById("meeting").value)}>
                                {this.props.meetings.map((meeting,index) => (
                                    <option value={meeting.sessionsLink} key={index}>{meeting.name}</option>))}
                            </select>
                            <select defaultValue={this.props.selectedRace} style={{width:"210px"}} id="race" onChange={() => this.props.getGrid(document.getElementById("race").selectedIndex)}>
                                {this.props.races.map((race,index) => (
                                    <option value={index} key={index}>{race.name}</option>))}
                            </select>
                        </div>
                        <div className='buttonRow'>GRID
                            
                            <button attr1='gridShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='gridPage' className="controlBUTTON" onClick={this.props.controlSend}>Page</button>
                            <button attr1='gridHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRow'>DATA COL 
                            <button attr1='col4Gap' className="controlBUTTON" onClick={this.props.controlSend}>Gap</button>
                            <button attr1='col4Diff' className="controlBUTTON" onClick={this.props.controlSend}>Diff</button>
                            <button attr1='col4Pos' className="controlBUTTON" onClick={this.props.controlSend}>Pos</button>
                            {/* <button attr1='col4Pits' className="controlBUTTON" onClick={this.props.controlSend}>Pits</button> */}
                            <button attr1='col4None' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        
                    </div>
                </div>
                )
        } else {
            return(         
            <div className='box' style={{backgroundColor: this.props.color}}>
                <div className='boxHeader' onClick={this.props.collapse}>
                    BASIC CONTROLS
                </div>
                <div className='buttonBox'>     
                    <div className='buttonRow'>CLOCK 
                        <button attr1='clockShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                        <button attr1='clockHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                    </div>
                    {/* <div className='buttonRowRight'>BATTLE 
                        <button attr1='battleShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                        <button attr1='battleClear' className="controlBUTTON" onClick={this.props.controlSend}>Clear</button>                    
                    </div>                */}
                    <div className='buttonRow'>TOWER 
                        <button attr1='towerShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                        <button attr1='towerHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                    </div>
                    {/* <div className='buttonRowRight'>ADVANCE
                        <button attr1='progressionShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                        <button attr1='progressionClear' className="controlBUTTON" onClick={this.props.controlSend}>Clear</button>                    
                    </div>   */}
                    <div className='buttonRow'>RESULTS 
                        <button attr1='resultsShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                        <button attr1='resultsPage' className="controlBUTTON" onClick={this.props.controlSend}>Page</button>
                        <button attr1='resultsHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                    </div>
                    <div className='buttonRow'>GRID
                        <button attr1='gridShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                        <button attr1='gridPage' className="controlBUTTON" onClick={this.props.controlSend}>Page</button>
                        <button attr1='gridHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                    </div>
                    <div className='buttonRow'>DATA COL 
                        <button attr1='col4Gap' className="controlBUTTON" onClick={this.props.controlSend}>Gap</button>
                        <button attr1='col4Diff' className="controlBUTTON" onClick={this.props.controlSend}>Diff</button>
                        <button attr1='col4Pos' className="controlBUTTON" onClick={this.props.controlSend}>Pos</button>
                        {/* <button attr1='col4Pits' className="controlBUTTON" onClick={this.props.controlSend}>Pits</button> */}
                        <button attr1='col4None' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                    </div>
                    
                </div>
            </div>
            )
        }
        
    }
}

export default BasicControls;